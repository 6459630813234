import React, { useEffect } from "react";
import { trackEvent, trackSelect } from "../../lib/utils/analytics";
import useGameStore from "../../lib/store/GameStore";
import { shallow } from "zustand/shallow";
import { useRouter } from "next/router";
import {
  extractDateFromURLPath,
  gridLevelDateKey,
} from "../../lib/utils/gridLevels";

export default function TabGroup(params) {
  const [selectedTab, setSelectedTab] = useGameStore(
    (state) => [state.selectedTab, state.setSelectedTab],
    shallow
  );

  const router = useRouter();
  const { pathname, query } = router;
  const { tab } = query;

  useEffect(() => {
    if (tab !== undefined) {
      setSelectedTab(tab);
    }
  }, [tab]);

  function onTabClick(tab) {
    const dateKey = extractDateFromURLPath();
    if (dateKey) {
      router.push({
        query: { tab, date: gridLevelDateKey },
        shallow: true,
      });
    } else {
      router.push({
        query: { tab },
        shallow: true,
      });
    }

    setSelectedTab(tab);

    trackEvent(`tab_${tab}`);
  }

  return (
    <>
      {/* <div className="mb-4 mt-4 inline-flex min-w-max rounded-lg" role="group">
        <button
          type="button"
          onClick={() => onTabClick("my")}
          className={`${
            tab == "my" || tab == undefined
              ? "bg-[#ffeebc] text-red-900 sm:hover:bg-[#ffeebc]"
              : "bg-white text-gray-900"
          } rounded-l-lg border border-gray-200 px-4 py-2 text-center text-xs font-semibold  uppercase text-gray-600 focus:z-10  focus:text-red-700 focus:ring-red-700 dark:border-gray-600 dark:bg-gray-700 dark:text-gray-300 dark:hover:bg-gray-600 dark:hover:text-white dark:focus:text-white dark:focus:ring-red-500 sm:hover:bg-[#fff3cf] sm:hover:text-red-700 md:py-2 md:text-sm`}
        >
          My Grid
        </button>
        <button
          type="button"
          onClick={() => onTabClick("popular")}
          className={`${
            tab == "popular"
              ? "bg-[#ffeebc] text-red-900 sm:hover:bg-[#ffeebc]"
              : "bg-white text-gray-900  "
          } border-b border-t border-gray-200 px-4 py-1 text-center text-xs font-semibold  uppercase text-gray-600 focus:z-10  focus:text-red-700 focus:ring-red-700 dark:border-gray-600 dark:bg-gray-700 dark:text-gray-300 dark:hover:bg-gray-600 dark:hover:text-white dark:focus:text-white dark:focus:ring-red-500 sm:hover:bg-[#fff3cf] sm:hover:text-red-700 md:py-2 md:text-sm`}
        >
          Most Popular
        </button>
        <button
          type="button"
          onClick={() => onTabClick("leaderboard")}
          className={`${
            tab == "leaderboard"
              ? "bg-[#ffeebc] text-red-900 sm:hover:bg-[#ffeebc]"
              : "bg-white text-gray-900"
          } rounded-r-md border border-gray-200 px-4 py-1 text-center text-xs font-semibold  uppercase text-gray-600 focus:z-10  focus:text-red-700 focus:ring-red-700 dark:border-gray-600 dark:bg-gray-700 dark:text-gray-300 dark:hover:bg-gray-600 dark:focus:text-white dark:focus:ring-red-500 sm:hover:bg-[#fff3cf] sm:hover:text-red-700 sm:dark:hover:text-white md:py-2 md:text-sm`}
        >
          Leaderboard
        </button>
      </div> */}
      <div className="mx-auto mb-4 mt-4 flex w-min items-center justify-center space-x-1 whitespace-nowrap rounded-full border bg-white  p-1 text-center text-md font-medium">
        <button
          onClick={() => onTabClick("my")}
          className={`rounded-full px-4 py-1 ${
            selectedTab == "my" || selectedTab == undefined
              ? "bg-yellow-600 text-white"
              : "text-gray-700 hover:bg-gray-200 hover:text-gray-800"
          } `}
        >
          My Grid
        </button>
        <button
          onClick={() => onTabClick("popular")}
          className={`rounded-full px-4 py-1 ${
            selectedTab === "popular"
              ? "bg-yellow-600 text-white"
              : "text-gray-700 hover:bg-gray-200 hover:text-gray-800"
          } `}
        >
          Most Popular
        </button>
        <button
          onClick={() => onTabClick("leaderboard")}
          className={`rounded-full px-4 py-1 ${
            selectedTab === "leaderboard"
              ? "bg-yellow-600 text-white"
              : "text-gray-700 hover:bg-gray-200 hover:text-gray-800"
          } `}
        >
          Leaderboard
        </button>
      </div>
    </>
  );
}
